
button {
    border: none;
    background: none;
    cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.income-modal {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__sum-container {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    &__total-sum {
        color: #10B981;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
    }

    &__input-container {
        flex-grow: 1;
        position: relative;
    }

    &__input-label {
        position: absolute;
        top: 8px;
        left: 12px;
        font-size: 12px;
        color: grey;
        transition: all 0.3s ease;
        pointer-events: none;
    }

    &__input:not(:placeholder-shown) + &__input-label,
    &__input:focus + &__input-label {
        top: -1px;
        left: 12px;
        font-size: 10px;
        color: rgb(194, 62, 255);
    }

    &__input {
        width: 100%;
        padding: 16px 12px 3px 12px;
        border: 1px solid #A6A6A6;
        border-radius: 10px;
        font-size: 16px;

        &:focus {
            outline: none;
            border-color: rgb(194, 62, 255);
            box-shadow: 0 0 5px rgba(231, 113, 255, 0.7);
        }

        &::placeholder {
            color: #575757;
        }

        &-container-relative {
            position: relative;
        }

        &-additional-info {
            font-size: 12px;
            color: #575757;

            position: absolute;
            left: 8px;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        color: #575757;

        margin: 16px 0;
    }

    &__product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 22px;

        &-title {
            width: 25%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #1C1C1C;
        }
    }

    &__submit-button {
        align-self: end;
        background: #B9439D;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 8px 12px;
        border-radius: 4px;
    }
}

.validation-error {
    color: red;

    &-input {
        border-color: red;
        box-shadow: 0 0 5px red;

        &:focus {
            outline: none;
            border-color: red;
            box-shadow: 0 0 5px red;
        }
    }
}

.closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon {
    cursor: pointer;
}

.modal-transition {
    &-enter {
        opacity: 0;

        & .income-modal {
            opacity: 0;
            transform: scale(0.1, 0.1);
        }
    }

    &-enter-active {
        transition: opacity 0.3s ease, transform 0.3s ease;

        & .income-modal {
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }

    &-enter-to {
        opacity: 1;

        & .income-modal {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-leave {
        opacity: 1;

        & .income-modal {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-leave-active {
        transition: opacity 0.3s ease, transform 0.3s ease;

        & .income-modal {
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }

    &-leave-to {
        opacity: 0;

        & .income-modal {
            opacity: 0;
            transform: scale(0.8);
        }
    }
}
