
.quick_messages {
    position: absolute;
    width: 100%;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    min-height: 480px;
    border-radius: 8px;
    padding: 0 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 #00000026;
    z-index: 2001;
    user-select: none;
    text-decoration: none;
    animation-name: fadeInQM;
    animation-duration: 0.3s;
    color: #1C1C1C;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__title {
        margin-top: 10px;
    }

    &__fresh-open-title {
        cursor: pointer;
    }

    &__header {
        display: grid;
        grid-auto-flow: column;
        align-items: self-start;
        margin-top: 8px;
    }

    &__points {
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        font-weight: 500;

        .point_active {
            color: #fff;
            background-color: #575757;
        }

        li {
            width: auto;
            padding: 3px 8px;
            border: 1px solid #A6A6A6;
            background-color: #fff;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                border-color: #B9439D;
            }

            &:nth-child(2) {
                margin-left: 0.5rem;
            }

            &:nth-child(3) {
                margin-left: 0.5rem;
            }
            @media (max-width: 420px){
                padding: 5px;
            }
        }
    }

    .nextAnimation {
        animation-name: fadeInQMList;
        animation-duration: 0.3s;

        &::before {
            cursor: pointer;
            content: url('../../../../assets/arrow-left-qm.svg');
        }
    }

    .nextReverseAnimation {
        animation-name: fadeInQMListReverse;
        animation-duration: 0.3s;
    }

    &__footer {
        padding: 8px 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;

        .uinput {
            width: calc(100% - 20px);
            border-radius: 4px;
            padding: 4px 8px;
            background-color: #E6E6E6;
            border: none;
        }
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

@keyframes fadeInQMListReverse {
    from {
        opacity: 0;
        transform: translateX(-40px);
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInQMList {
    from {
        opacity: 0;
        transform: translateX(40px);
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInQM {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
    }
}
@media (max-width: 420px){
    .close-dropdown {
        display: none;
    }
}
