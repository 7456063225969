
.smiles-dropdown {
    position: absolute !important;
    overflow: visible !important;
    max-width: 300px;
    min-width: 300px;
    height: 200px;
    bottom: 25px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translateX(-270px);
    background: #fff;
    border-radius: 4px;
    padding: 10px 15px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: .2s;
    z-index: 111111114;

    &.--show {
        bottom: 35px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    &:after,
    &:before {
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        bottom: -10px;
        right: 13px;
    }

    &:before {
        border-width: 6px;
        border-top-color: #dbdbdb;
        bottom: -12px;
        right: 12px;
    }

    &:after {
        border-width: 5px;
        border-top-color: #fff;
    }

    &__scroll {
        display: block;
        overflow: hidden scroll;
        height: 100%;
        width: calc(100% + 13px);
    }

    &__group {
        margin: 0 0 10px;
    }

    &__head {
        font-weight: 700;
        padding: 0 0 0 4px;
    }

    &__body {
        margin: 0 -3px;
        font-weight: 400;
    }
}
