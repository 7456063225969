
button {
    border: none;
    background: none;
    cursor: pointer;
}

.products-list {
    &__wrapper {
        margin: 0 auto 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 400px;
        background: white;
        border-radius: 16px;
        padding: 16px;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 12px;

        th,
        td {
            padding: 8px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        th {
            border-bottom: 1px solid #1c1c1c;
            color: #575757;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
        }

        td:last-child {
            text-align: end;
        }

        td .icon {
            margin: 0 8px;
            cursor: pointer;
        }
    }

    &__additional-info {
        font-size: 10px;
        color: #575757;

        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    &__additional-info-relative {
        position: relative;
    }

    &__add-button {
        color: #b9439d;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        width: fit-content;

        &:hover {
            color: rgba(185, 67, 157, 0.8);
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
        margin: 0 5px;
        padding: 5px 10px;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }

        &.active {
            font-weight: bold;
            background-color: #b9439d;
            border-radius: 4px;
            color: white;
        }
    }
}
