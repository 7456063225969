<template>
  <div :class="mergeClasses">
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		classes: {
			type: String,
			required: false,
			default: '',
		}
	},

	computed: {
		mergeClasses() {
			return 'content ' + this.classes;
		}
	}
};
</script>

<style scoped>
    .content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
</style>