
.whatsapp-dont-exist-wrap {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.whatsapp-dont-exist {
    color: #ef5350;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #ffd8d8;
    background: #fff5f5;
    flex: 1;
}

.icon-wrapper-whatsapp {
    display: none;
    position: absolute;
    right: -41px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ffd8d8;
    background: #fff5f5;
    margin-left: 10px;
    color: #ef5350;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bpm-tracker-message {
    color: #1D4ED8;

    img {
        position: relative;
        top: 3px;
    }

    span {
        color: #575757;
    }

    .bpm-gif {
      width: 15px;
      height: 15px;
    }
}

.message__name.bpm-tracker-message {
	&::before {
		top: 3px;
	}
}

.no-reponse-required {
  margin-top: 15px;
  margin-left: 39px;
  background: #B9439D;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.no-reponse-required.--bpm {
	margin-left: 0;
	margin-right: 39px;
}
