<template>
  <div class="communication">
    <AppFunnelTransferComponent v-if="!deal.readOnly || isDealTransferringToCurrentManager" />
    <AppFunnelUnionComponent />
    <div class="bpm-search">
      <BpmSwitch
        v-if="manager.module_bpm_tracker && !isSearch && !isMedia"
      />
      <CommunicationMediaComponent 
        :class="{ 'move-right': !manager.module_bpm_tracker && !isSearch && !isMedia }" 
        @set-new-limit="setNewLimit"
      />
    </div>
    <AppFunnelMessagesComponent
      v-if="!isMedia"
      ref="message-component"
    />
    <MediaMessagesComponent v-if="isMedia" />
    <AppFunnelMessageFormComponent v-if="!isMedia" />
  </div>
</template>

<script>
import AppFunnelMessagesComponent from '@/components/funnel/AppFunnelMessagesComponent';
import AppFunnelMessageFormComponent from '@/components/funnel/AppFunnelMessageFormComponent';
import BpmSwitch from '@/components/BpmTracker/BpmSwitch.vue';
import { mapActions, mapState } from 'vuex';
import AppFunnelTransferComponent from '@/components/funnel/AppFunnelTransferComponent.vue';
import AppFunnelUnionComponent from '@/components/funnel/AppFunnelUnionComponent.vue';
import CommunicationMediaComponent from '../CommunicationMedia/CommunicationMediaComponent.vue';
import MediaMessagesComponent from '../CommunicationMedia/Media/MediaMessagesComponent.vue';

export default {
	name: 'AppFunnelCommunicationComponent',
	components: {
		AppFunnelUnionComponent,
		AppFunnelTransferComponent,
		BpmSwitch,
		CommunicationMediaComponent,
		MediaMessagesComponent,
		AppFunnelMessagesComponent,
		AppFunnelMessageFormComponent,
	},
	data() {
		return {
			isSearching: false,
		};
	},
	computed: {
		...mapState({
			manager: state => state.manager,
			isSearch: state => state.communicationMedia.isSearch,
			isMedia: state => state.communicationMedia.isMedia,
			deal: state => state.modal.deal.data,
		}),
		isDealTransferringToCurrentManager() {
			return (this.deal.transferManagerTo === this.manager.manager_id) || this.deal.transferToGroupsStatus === 'in';
		}
	},
	watch: {
		'deal.id': {
			handler() {
				this.getClientDeals();
			},
			immediate: true,
		},
	},
	mounted() {
		this.getDocumentCategories('profile');
	},
	methods: {
		...mapActions({
			getDocumentCategories: 'documents/getDocumentCategories',
		}),
		async getClientDeals() {
			await this.$store.dispatch('modal/fetchClientDeals');
		},
		setNewLimit(limit) {
			const messageComponent = this.$refs['message-component'];
			
			if (messageComponent && limit) {
				messageComponent.limit = limit;
			}
		},
	},
};
</script>
<style>
div + .communication {
    height: calc(100% - 42px);
}

.bpm-search {
	display: flex;
	justify-content: space-between;
	padding-top: 9px;
	padding-bottom: 4px;
	background: white;
	align-items: center;
}

.move-right {
	margin-left: auto;
}
</style>
